import React from 'react'
import Row  from 'react-bootstrap/Row';

import { injectIntl } from "gatsby-plugin-intl"

import styles from './keyconcepts.module.scss'

import GeneralHIcon from '../../data/images/KC-1.inline.svg'
import HouseworkCIcon from '../../data/images/KC-2.inline.svg'
import HouseworkPIcon from '../../data/images/KC-3.inline.svg'
import EducationIcon from '../../data/images/KC-4.inline.svg'
import Downarrowdark from '../../data/images/down-arrow.inline.svg'

const KeyConcepts = ({ intl }) => {
    return (
        
        <div className={styles.KeyConcepts + " light-bck"} id="key-concepts">
            <div className="container pb-5">
            <Row>
                <h2 className={styles.title + " col-md-6 offset-md-3"}>{intl.formatMessage({ id: "keyconcepts.title" })}</h2>
                <div className="col-12">
                    <hr className="separator"></hr>
                </div>
            </Row>
            <Row>
                <div className={styles.Concept + " col-lg-3 col-md-6 col-8 offset-2 offset-md-0"}>
                    <GeneralHIcon className="kcicon"/>
                    <h4>{intl.formatMessage({ id: "keyconcepts.concept_1_title" })}</h4>
                    <p>{intl.formatMessage({ id: "keyconcepts.concept_1_text" })}</p>
                </div>
                <div className={styles.Concept + " col-lg-3 col-md-6 col-8 offset-2 offset-md-0"}>
                    <HouseworkCIcon className="kcicon"/>
                    <h4>{intl.formatMessage({ id: "keyconcepts.concept_2_title" })}</h4>
                    <p>{intl.formatMessage({ id: "keyconcepts.concept_2_text" })}</p>
                </div>
                <div className={styles.Concept + " col-lg-3 col-md-6 col-8 offset-2 offset-md-0"}>
                    <HouseworkPIcon className="kcicon"/>
                    <h4>{intl.formatMessage({ id: "keyconcepts.concept_3_title" })}</h4>
                    <p>{intl.formatMessage({ id: "keyconcepts.concept_3_text" })}</p>
                </div>
                <div className={styles.Concept + " col-lg-3 col-md-6 col-8 offset-2 offset-md-0"}>
                    <EducationIcon className="kcicon"/>
                    <h4>{intl.formatMessage({ id: "keyconcepts.concept_4_title" })}</h4>
                    <p>{intl.formatMessage({ id: "keyconcepts.concept_4_text" })}</p>
                </div>

            </Row>
        </div>
        <div className="movingbutton"><a href="#how-do-you-compare" className="arrow-dark"><div className="text-center"> <Downarrowdark className="down-arrow-dark" /><b>Keep Discovering</b><Downarrowdark className="down-arrow-dark" /></div></a>
        </div>
        </div>
    )
}

export default injectIntl(KeyConcepts)