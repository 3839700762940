import React, { useEffect } from 'react'

import { ResponsiveStream  } from '@nivo/stream'
import Row  from 'react-bootstrap/Row';

import styles from './streamgraph2.module.scss'

import { useBaseQuery } from '../../hooks/use-base-query'

const StreamGraph = ( { criteriacode, gender} ) => {

    const querydata = useBaseQuery()

    const MEN = "M"
    const WOMEN = "F"

    const getCareHrs = (node) => {
        if( gender === MEN){
            return Number(node.hyc1t).toFixed(2)
        }else if ( gender === WOMEN ){
            return Number(node.hyc2t).toFixed(2)
        }else{
            return Number( (Number(node.hyc1t) + Number(node.hyc2t)) / 2 ).toFixed(2) 
        }
    }

    const getHwkHrs = (node) => {
        if( gender === MEN){
            return Number(node.hyh1t).toFixed(2)
        }else if ( gender === WOMEN ){
            return Number(node.hyh2t).toFixed(2)
        }else{
            return Number( (Number(node.hyh1t) + Number(node.hyh2t)) / 2 ).toFixed(2) 
        }
    }

    const getEduHrs = (node) => {
        if( gender === MEN){
            return Number(node.oe01t).toFixed(2)
        }else if ( gender === WOMEN ){
            return Number(node.oe02t).toFixed(2)
        }else{
            return Number( (Number(node.oe01t) + Number(node.oe02t)) / 2 ).toFixed(2) 
        }
    }

    const createReportData = () => {
        
        const raw = querydata[criteriacode].edges
        const graphData = raw.map( i=> ({
            "Direct Care": getCareHrs(i.node),
            Housework: getHwkHrs(i.node),
            Education: getEduHrs(i.node),
        }))
        
        return graphData
    }

    // let data = streamGraphInfo
    let data = createReportData()

    useEffect(() => {
        data = createReportData()
    });

    const note = () => {
        return (
            <Row className="border border-dark text-justify mt-3">
                
                <span className="notes p-3">
                <b>Notes:</b> <a href="http://www.countingwomenswork.org">Counting Women’s Work</a> project estimates. The current country database includes estimates from seventeen countries: Bangladesh, Colombia, Costa Rica, Germany, Ghana, India, Italy, Mauritius, Mexico, Mongolia, Senegal, South Africa, Spain, Thailand, United States, Uruguay, and Vietnam.  The world and other average measures are equal-weighted averages of countries in that region or group.  Consumption age profiles are calculated based on how much unpaid care work time is produced in each household and the household structure.  See the <a href="http://www.countingwomenswork.org">Counting Women’s Work</a> website for details and access to estimates.
                </span>
            </Row>
        ) 
    }

    return (
        <>
            <div className={styles.test}>
                <ResponsiveStream
                    data={data}
                    keys={[ 'Direct Care', 'Housework', 'Education' ]}
                    margin={{ top: 110, right: 10, bottom: 10, left: 60 }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={{ orient: 'left', tickSize: 5, tickPadding: 5, tickRotation: 0, legend: 'Hours per Week', legendPosition: 'middle', legendOffset: -40 }}
                    offsetType="diverging"
                    colors={{ scheme: 'category10' }}
                    fillOpacity={0.85}
                    borderColor={{ theme: 'background' }}
                    dotSize={8}
                    dotColor={{ from: 'color' }}
                    dotBorderWidth={2}
                    dotBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.7 ] ] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    tooltipFormat={object => `${object.value} (${object.index} yrs)`}
                    legends={[
                        {
                            anchor: 'top',
                            direction: 'row',
                            translateY: -60,
                            itemWidth: 120,
                            itemHeight: 20,
                            itemTextColor: '#999999',
                            symbolSize: 24,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000000'
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </div>
            <div>
                <p className="text-center mb-4 graph-legend">Age</p>
                <Row>
                    <span><b>Source: </b>Counting Women's Work</span>
                </Row>
                {note()}
            </div>
        </>
    )
}

export default StreamGraph