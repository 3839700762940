import React, { useState } from 'react'
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import StreamGraph from "../home/streamgraph2"
import { TwitterShareButton, TwitterIcon,FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from 'react-share'

import { injectIntl } from "gatsby-plugin-intl"

import MaleAvatar from '../../data/images/MaleC.inline.svg'
import FemaleAvatar from '../../data/images/FemaleC.inline.svg'
import InfoIcon from '../../data/images/info_icon.inline.svg'
import CriteriaSelector from '../utils/criteriaSelector'
import AgainstAverage from '../utils/againstAverage'
import Carousel1 from '../utils/carousel1'
import styles from './dataentry.module.scss'
import Downarrowwhite from '../../data/images/down-arrowwhite.inline.svg'


const DataEntry = ({ intl }) => {
 
    const [gender, setGender] = useState("M")
    const handleGenderChange = (val) => {
        setGender(val)
    }
    const [vsgender, setVsGender] = useState("O")
    const handleVsGenderChange = (val) => {
        setVsGender(val)
    }
    const [age, setAge] = useState(20)
    const handleAgeChange = (event) => {      
        let num_age = parseInt(event.target.value)
        if(isNaN(num_age) || age < 0){
            setAge(0)
        }else if(age > 90){
            setAge(90)
        }else{
            setAge(num_age)
        }
    }
    const [careHrs, setCareHrs] = useState("")
    const handleCareHrsChange = (event) => {
        setCareHrs(event.target.value)
    }
    const [hwkHrs, setHwkHrs] = useState("")
    const handleHwkHrsChange = (event) => {
        setHwkHrs(event.target.value)
    }
    const [eduHrs, setEduHrs] = useState("")
    const handleEduHrsChange = (event) => {
        setEduHrs(event.target.value)
    }

    let showTotalResults = (careHrs !== "" && hwkHrs !== "" && eduHrs !== "")
    const getResultsLabel = () => {
        if(showTotalResults){
            return "dataentry.time_spent.header.results"
        }
        return "dataentry.time_spent.header.unpaid_care_work"
    }
    let totalHrs = Number(careHrs) + Number(hwkHrs) + Number(eduHrs)

    const [criteriaDataEntry, setCriteriaDataEntry] = useState("The World")
    const handleCriteriaDataEntryChange = (event) => {
        setCriteriaDataEntry(event.target.value)
    }
    let criteriaDataEntryCode = criteriaDataEntry.replace(/\s+/g, '_').toLowerCase()
    
    const [graphCriteriaDataEntry, setGraphCriteriaDataEntry] = useState("The World")
    const handleGraphCriteriaDataEntryChange = (event) => {
        setGraphCriteriaDataEntry(event.target.value)
    }
    let graphCriteriaDataEntryCode = graphCriteriaDataEntry.replace(/\s+/g, '_').toLowerCase()

    const [diffVSMen, setDiffVSMen] = useState(0)
    const [labelVSMen, setLabelVSMen] = useState("")
    const [diffVSWomen, setDiffVSWomen] = useState(0)
    const [labelVSWomen, setLabelVSWomen] = useState("")

    return (
        <> 
            <div className={styles.DataEntry + " dark-bck dataentry"} id="how-do-you-compare">
                <div className="container">
                    <Row className="pt-lg-5">
                        <Col className="col-lg-5 col-12">
                        <h2 className={styles.title + " col-12 text-left pb-3"}>{intl.formatMessage({ id: "dataentry.title" })}</h2>
                            
                        </Col>
                        <Col className="col-lg-6 offset-lg-1 col-12">
                        <Col className="col-md-12 text-left">
                            <h5 className="pb-4">{intl.formatMessage({ id: "dataentry.intro_text" })}</h5>
                        <Row>
                        <Col className="col-md-6 col-12 text-left">
                            <p className={styles.title + "  text-left "}>{intl.formatMessage({ id: "dataentry.gender_select.title" })}</p>
                            <ToggleButtonGroup type="radio" name="gender" className={styles.title + " pb-3 dark-bck"} value={gender} onChange={handleGenderChange}>
                                <ToggleButton name="male" value={"M"} variant="secondary">{intl.formatMessage({ id: "dataentry.gender_select.male" })}</ToggleButton>
                                <ToggleButton name="female" value={"F"} variant="secondary">{intl.formatMessage({ id: "dataentry.gender_select.female" })}</ToggleButton>
                            </ToggleButtonGroup>
                            
                        </Col>
                        <Col className="col-md-6 col-12 text-left">
                            <p className={styles.title + " text-left"}>{intl.formatMessage({ id: "dataentry.age_select.title" })}</p>
                            <FormControl
                                    name="age"
                                    type="number"
                                    placeholder="20"
                                    onChange={handleAgeChange}
                                    min={0}
                                    max={90}
                                    maxLength={2}
                                />
                        </Col>
                        </Row>
                        </Col>
                        
                        <Col className="col-12">
                            <div className="justify-content-center pillinput"><p className="pt-2">{intl.formatMessage({ id: "dataentry.criteria_select.text" })}</p>
                                <CriteriaSelector value={criteriaDataEntry} onChange={handleCriteriaDataEntryChange}/>
                            </div>
                            <br></br>
                        </Col>
                        </Col>
                        
                    </Row>
                    
                    <Row>
                        <div className="col-12">
                            <hr className="separator"></hr>
                        </div>
                        <p  className={styles.title + " col-md-8 offset-md-2"}>
                            {intl.formatMessage({ id: "dataentry.time_spent.subtitle" })}
                            <strong>
                                {criteriaDataEntry}
                            </strong>
                        </p>
                    </Row>
                    
                    
                    <Row className="pb-3 d-none d-sm-flex">
                        <div className="col-3"></div>
                        <div className={styles.dataentryheader + " col-3"}>
                            {gender === "M" ? (
                                <MaleAvatar className="col-5 d-none d-lg-block"/>
                            ) : (
                                <FemaleAvatar className="col-5 d-none d-lg-block"/>
                            )}
                            <h4 className={styles.title + " col-lg-7 col-12 text-left pt-4"}>{intl.formatMessage({ id: "dataentry.time_spent.header.you" })}</h4>
                        </div>
                        <div className={styles.dataentryheader + " col-3"}>
                            <MaleAvatar className="col-5 d-none d-lg-block"/>
                            <div className="col-lg-7 col-12 text-left pt-4">
                                <h4>
                                    { intl.formatMessage({ id: "dataentry.time_spent.header.avg_men" })}
                                </h4>
                                <h4>
                                    {"("+age+" yrs)"}
                                </h4>
                            </div>
                        </div>
                        <div className={styles.dataentryheader + " col-3"}>
                            <FemaleAvatar className="col-5 d-none d-lg-block"/>
                            <div className="col-lg-7 col-12 text-left pt-4">
                                <h4>
                                    { intl.formatMessage({ id: "dataentry.time_spent.header.avg_women" })}
                                </h4>
                                <h4>
                                    {"("+age+" yrs)"}
                                </h4>
                            </div>
                        </div>
                    </Row>
                    <Row className="mb-3 box-results">
                        <div className=" col-md-3 col-5 m-0">
                            <div className={styles.dataentryrowheader + " col-11 px-1"}>
                                <h4 className={styles.textright + " col-10 m-0"}>{intl.formatMessage({ id: "dataentry.time_spent.header.care" })}</h4>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {intl.formatMessage({ id: "dataentry.time_spent.header.care_info" })}
                                        </Tooltip>
                                    }>
                                    <InfoIcon className="order-first order-md-last col-lg-2 col-md-3 col-4 px-2 "/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 col-6"}>
                            <InputGroup>
                                <FormControl
                                    name="carehrs"
                                    type="number"
                                    placeholder=""
                                    onChange={handleCareHrsChange}
                                    min="0"
                                    max="168"
                                    step="0.25"
                                    className={"form-control-input text-right " + (careHrs ? "" : "no-data")}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">{intl.formatMessage({ id: "dataentry.time_spent.labels.h_week" })}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3  sub-results"}>
                            <AgainstAverage className={"row pill " + (careHrs !== "" ? "" : "d-none")} hrs={careHrs} age={age} gender="M" criteriacode={criteriaDataEntryCode} concept="care"/>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 sub-results"}>
                            <AgainstAverage className={"row pill " + (careHrs !== "" ? "" : "d-none")}  hrs={careHrs} age={age} gender="F" criteriacode={criteriaDataEntryCode} concept="care"/>
                        </div>
                    </Row>
                    <Row className={"mb-3 box-results"  + (careHrs !== "" ? "" : " d-none")}>  
                        <div className=" col-md-3 col-5 m-0 ">
                            <div className={styles.dataentryrowheader + " col-11 px-1"}>
                                <h4 className={styles.textright + " col-10 m-0"}>{intl.formatMessage({ id: "dataentry.time_spent.header.housework" })}</h4>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {intl.formatMessage({ id: "dataentry.time_spent.header.housework_info" })}
                                        </Tooltip>
                                    }>
                                    <InfoIcon className="order-first order-md-last col-lg-2 col-md-3 col-4 px-2 "/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 col-6"}>
                            <InputGroup >
                                <FormControl
                                    name="hwkhrs"
                                    type="number"
                                    placeholder=""
                                    onChange={handleHwkHrsChange}
                                    min="0"
                                    max="168"
                                    step="0.25"
                                    className={"form-control-input text-right "  + (hwkHrs ? "" : "no-data")}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">{intl.formatMessage({ id: "dataentry.time_spent.labels.h_week" })}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 sub-results"}>
                            <AgainstAverage className={"row pill " + (hwkHrs ? "" : "d-none")} hrs={hwkHrs} age={age} gender="M" criteriacode={criteriaDataEntryCode} concept="hwk"/>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 sub-results"}>
                            <AgainstAverage className={"row pill " + (hwkHrs ? "" : "d-none")}  hrs={hwkHrs} age={age} gender="F" criteriacode={criteriaDataEntryCode} concept="hwk"/>
                        </div>
                    </Row>
                    <Row className={"mb-3 box-results" +  (careHrs !== "" && hwkHrs !== "" ? "" : " d-none")}>
                        <div className={" col-md-3 col-5 m-0 "}>
                            <div className={styles.dataentryrowheader + " col-11 px-1"}>
                                <h4 className={styles.textright + " col-10 m-0"}>{intl.formatMessage({ id: "dataentry.time_spent.header.education" })}</h4>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {intl.formatMessage({ id: "dataentry.time_spent.header.education_info" })}
                                        </Tooltip>
                                    }>
                                    <InfoIcon className="order-first order-md-last col-lg-2 col-md-3 col-4 px-2"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 col-6"}>
                            <InputGroup>
                                <FormControl
                                    name="eduhrs"
                                    type="number"
                                    placeholder=""
                                    onChange={handleEduHrsChange}
                                    min="0"
                                    max="168"
                                    step="0.25"
                                    className={"form-control-input text-right " + (eduHrs ? "" : "no-data")}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1">{intl.formatMessage({ id: "dataentry.time_spent.labels.h_week" })}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 sub-results"}>
                            <AgainstAverage className={"row pill " + (eduHrs !== "" ? "" : "d-none")}  hrs={eduHrs} age={age} gender="M" criteriacode={criteriaDataEntryCode} concept="edu"/>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 sub-results"}>
                            <AgainstAverage className={"row pill " + (eduHrs !== "" ? "" : "d-none")} hrs={eduHrs} age={age} gender="F" criteriacode={criteriaDataEntryCode} concept="edu"/>
                        </div>
                    </Row>
                    <Row className={"mb-3 box-results" + ( (careHrs !== "" && hwkHrs !== "") ? "" : " d-none")}>
                        <div className={"col-md-3 results-title " + ((showTotalResults) ? "" : "")}>
                            <h3 className={" pt-3"}>{intl.formatMessage({ id: getResultsLabel()})}</h3>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 pill-results " + ( (careHrs !== "" && hwkHrs !== "") ? "" : "d-none")}>
                            <h4> {Number(totalHrs).toFixed(2) + " " + intl.formatMessage({ id: "dataentry.time_spent.labels.h_week" })}</h4>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 col-6 pill-results"}>
                            <AgainstAverage className={"row  " + ( (careHrs !== "" && hwkHrs !== "") ? "" : "d-none")} hrs={Number(totalHrs).toFixed(2)} age={age} gender="M" criteriacode={criteriaDataEntryCode} concept="results" setDiffAmount={setDiffVSMen} setDiffLabel={setLabelVSMen}/>
                        </div>
                        <div className={styles.dataentryheader + " col-md-3 col-6 pill-results"}>
                            <AgainstAverage className={"row  " + ( (careHrs !== "" && hwkHrs !== "") ? "" : "d-none")} hrs={Number(totalHrs).toFixed(2)} age={age} gender="F" criteriacode={criteriaDataEntryCode} concept="results" setDiffAmount={setDiffVSWomen} setDiffLabel={setLabelVSWomen}/>
                        </div>
                    </Row>
                    <Row>
                        <div className={"offset-md-8 col-md-4 text-right " + ( (careHrs !== "" && hwkHrs !== "" && eduHrs !== "") ? "" : "d-none")}>
                            <span className="dark-bck d-inline-block p-2">Share your results:</span>
                            <FacebookShareButton className="d-inline-block p-2"
                                url="https://mywork.countingwomenswork.org/"
                                quote={`I work ${totalHrs} hours a week in unpaid activities. That is ${diffVSMen} hours ${labelVSMen} than men and ${diffVSWomen} hours ${labelVSWomen} than women of ${criteriaDataEntry} of ${age} years. See how you compare at `}>
                                <FacebookIcon id="facebook-share-btn" className="social-share" size='2rem'/>
                            </FacebookShareButton>
                            <TwitterShareButton className="d-inline-block p-2" 
                                url="https://mywork.countingwomenswork.org/"  
                                title={`I work ${totalHrs} hours a week in unpaid activities. That is ${diffVSMen} hours ${labelVSMen} than men and ${diffVSWomen} hours ${labelVSWomen} than women of ${criteriaDataEntry} of ${age} years. See how you compare at `}>
                                <TwitterIcon id="twitter-share-btn" className="social-share" size='2rem' />
                            </TwitterShareButton>
                            <WhatsappShareButton className="d-inline-block p-2" 
                                url="https://mywork.countingwomenswork.org/"
                                separator=" "
                                title={`I work ${totalHrs} hours a week in unpaid activities. That is ${diffVSMen} hours ${labelVSMen} than men and ${diffVSWomen} hours ${labelVSWomen} than women of ${criteriaDataEntry} of ${age} years. See how you compare at`}>
                                <WhatsappIcon id="whatsapp-share-btn" className="social-share" size='2rem' />
                            </WhatsappShareButton>
                        </div>
                    </Row>
                </div>
                <div className={styles.DataEntry }>
                    <div className="container  light-bck hardshadow">              
                        <Carousel1 />
                    </div>
                </div>
                <div className="movingbutton"><a href="#timeuse" className="arrow-white"><div className="text-center"> <Downarrowwhite className="down-arrow-white" /><b>Keep Discovering</b><Downarrowwhite className="down-arrow-white" /></div></a>
                </div>
            </div>
            
            <div className={styles.DataEntry + " light-bck timeuse"} id="timeuse">
                <div className="container">
                    <Row className="pt-2">
                        <h2 className={styles.title + " col-md-8 offset-md-2 pt-5"}>{intl.formatMessage({ id: "dataentry.timeuse.title" })}</h2>
                        <div className="col-12">
                            <hr className="separator"></hr>
                        </div>
                        
                    </Row>
                    <Row className="justify-content-center pillinput">
                        <Col className="col-lg-6">
                            <Row className="justify-content-center pillinput">
                                <CriteriaSelector className="col-8 d-inline" value={graphCriteriaDataEntry} onChange={handleGraphCriteriaDataEntryChange}/>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="pt-4 justify-content-center pillinput">
                        <Col className="col-lg-6">
                            <ToggleButtonGroup type="radio" name="vsgender" className={styles.title + " col-12 pb-3"} value={vsgender} onChange={handleVsGenderChange}>
                                <ToggleButton name="overall" value={"O"} variant="secondary">{intl.formatMessage({ id: "dataentry.timeuse.overall" })}</ToggleButton>
                                <ToggleButton name="male" value={"M"} variant="secondary">{intl.formatMessage({ id: "dataentry.timeuse.male" })}</ToggleButton>
                                <ToggleButton name="female" value={"F"} variant="secondary">{intl.formatMessage({ id: "dataentry.timeuse.female" })}</ToggleButton>
                            </ToggleButtonGroup>
                        </Col>
                    </Row>
                    <Row className="justify-content-center pillinput">
                        <div className="col-12">  <StreamGraph  dataEducation ={eduHrs}  dataAge={age} dataCare={careHrs} dataHousework={hwkHrs} gender={vsgender} criteriacode={graphCriteriaDataEntryCode} /></div>                   
                    </Row>
                    <Row>
                    {/* <ChartComponent  dataEducation ={eduHrs}  dataAge={age} dataCare={careHrs} dataHousework={hwkHrs} dataGender={vsgender} dataCountry={graphCriteriaDataEntryCode} /> */}
                    </Row>
                </div> 
               
            </div>            
            
        </>
    )
}

export default injectIntl(DataEntry)