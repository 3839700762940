import React from 'react'

import { injectIntl } from "gatsby-plugin-intl"

import Carousel from 'react-bootstrap/Carousel'
import Row  from 'react-bootstrap/Row';

const Carousel1 = ({ intl }) => {

    return (
        <div className="carousel-facts">
            <Row className="pt-4">
                <h3>{intl.formatMessage({ id: "dataentry.related_facts.title" })}</h3>
            </Row>
            <Row className="pt-4">
                <Carousel className="pb-5">
                    <Carousel.Item>
                        <p>
                            {intl.formatMessage({ id: "dataentry.related_facts.fact_1" })}.
                        </p>
                    </Carousel.Item>
                    <Carousel.Item>
                        <p>
                            {intl.formatMessage({ id: "dataentry.related_facts.fact_2" })}.
                        </p>
                    </Carousel.Item>
                    <Carousel.Item>
                        <p>
                            {intl.formatMessage({ id: "dataentry.related_facts.fact_3" })}.
                        </p>
                    </Carousel.Item>
                </Carousel>
            </Row>
        </div>
    )
}

export default injectIntl(Carousel1)