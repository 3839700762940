import React from 'react'

import Layout from '../components/common/layout'
import Head from '../components/common/head'
import Intro from '../components/home/intro'
import Keyconcepts from '../components/home/keyconcepts'
import DataEntry from '../components/home/dataentry'
import BookCover from '../components/home/bookcover'



const IndexPage = () => {
    return (
        <Layout>
            <Head title="Home"/>
            <Intro />
            <Keyconcepts />
            <DataEntry />
            <BookCover />
        </Layout>
    )
}

export default IndexPage