import React from 'react'
import { injectIntl } from "gatsby-plugin-intl"
import Jumbotron from "react-bootstrap/Jumbotron"
import styles from './intro.module.scss'
import Table from '../../data/images/main-table.inline.svg'


const Intro = ({ intl }) => {
    return (
        
            <Jumbotron className={styles.Jumbotron  + " light-bck sticky-modules"} id="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 z1">
                            <h1 className="pb-3">{intl.formatMessage({ id: "intro.title" })}</h1>
                            <h3 className="pb-3">{intl.formatMessage({ id: "intro.subtitle" })}</h3>
                            <p >
                                {intl.formatMessage({ id: "intro.paragraph" })}
                                <br></br>
                            </p>
                            <p>
                                {intl.formatMessage({ id: "intro.call_to_action_1" })}
                                <a href="https://www.countingwomenswork.org/about/methodology" target="_blank" rel="noopener noreferrer">
                                    {intl.formatMessage({ id: "intro.call_to_action_2" })}
                                </a>
                                {" " + intl.formatMessage({ id: "intro.call_to_action_3" })}
                            </p>
                        </div>
                        <div  className="col-lg-5">
                            <Table className="mainSvg"/> 
                        </div>
                    </div>
                   
                   
                   
                   
                   
                </div>
            </Jumbotron>       
        
    )
}

export default injectIntl(Intro)