import React, {useEffect} from 'react'

import { useBaseQuery } from '../../hooks/use-base-query'

import EqualIcon from '../../data/images/equal_icon.inline.svg'
import MoreIcon from '../../data/images/more_icon.inline.svg'
import LessIcon from '../../data/images/less_icon.inline.svg'

import { injectIntl } from "gatsby-plugin-intl"

const AgainstAverage = ({className, intl, concept, hrs, gender, age, criteriacode, setDiffAmount, setDiffLabel}) => {
    
    const querydata = useBaseQuery()

    const getData = () => {       
        if(isNaN(age) || age < 0){
            age = 0
        }else if(age > 90){
            age = 90
        }

        let raw = querydata[criteriacode].edges
        if( concept === "care" ){
            if(gender === "M"){
                return Number(raw[age].node.hyc1t).toFixed(2)
            } else {
                return Number(raw[age].node.hyc2t).toFixed(2)
            }
            
        }else if( concept === "hwk" ){
            if(gender === "M"){
                return Number(raw[age].node.hyh1t).toFixed(2)
            } else {
                return Number(raw[age].node.hyh2t).toFixed(2)
            }
        }else if( concept === "edu" ){
            if(gender === "M"){
                return Number(raw[age].node.oe01t).toFixed(2)
            } else {
                return Number(raw[age].node.oe02t).toFixed(2)
            }
        }else if( concept === "results" ){
            if(gender === "M"){
                let totalHrs = 
                    Number(Number(raw[age].node.hyc1t).toFixed(2)) +
                    Number(Number(raw[age].node.hyh1t).toFixed(2)) +
                    Number(Number(raw[age].node.oe01t).toFixed(2))
                return Number(totalHrs).toFixed(2)
            } else {
                let totalHrs = 
                    Number(Number(raw[age].node.hyc2t).toFixed(2)) +
                    Number(Number(raw[age].node.hyh2t).toFixed(2)) +
                    Number(Number(raw[age].node.oe02t).toFixed(2))
                return Number(totalHrs).toFixed(2)
            }
        }else{
            return 0
        }
        
    }

    let data = getData()

    useEffect(() => {
        data = getData()
    });

    const getResultsText = () => {
        let diff = Math.abs(data - Number(hrs)).toFixed(2)
        //console.log(`data: ${data} - hrs entry: ${hrs} = diff: ${diff}`)
        let result
        let genderlabel 
        if(gender === "M") {
            genderlabel = intl.formatMessage({ id: "dataentry.time_spent.labels.men" })
        } else{
            genderlabel = intl.formatMessage({ id: "dataentry.time_spent.labels.women" })
        }

        let diffLabel
        if (data > Number(hrs)){
            diffLabel = intl.formatMessage({ id: "dataentry.time_spent.labels.less" })
        } else if (data < Number(hrs)){
            diffLabel = intl.formatMessage({ id: "dataentry.time_spent.labels.more" })
        } else {
            diffLabel = intl.formatMessage({ id: "dataentry.time_spent.labels.equal" })
        }

        if(setDiffAmount && setDiffLabel){
            setDiffAmount(diff)
            setDiffLabel(diffLabel)
        }

        let iconClass = "fas fa-equals color-white"
        if (data > Number(hrs)){
            iconClass = "fas fa-arrow-alt-circle-down color-red"
        } else if (data < Number(hrs)){
            iconClass = "fas fa-arrow-alt-circle-up color-green"
        }

        if(concept === "results" ){
            result = <span><span className="icon d-md-span d-span d-lg-none"><i className={iconClass}></i> </span>{intl.formatMessage({ id: "dataentry.time_spent.labels.you_spend" })} <strong> {diff + intl.formatMessage({ id: "dataentry.time_spent.labels.h_week" })+ " " }</strong> {diffLabel} {intl.formatMessage({ id: "dataentry.time_spent.labels.than_average" })} {genderlabel}</span>
        }else{
            result = <span><span className="icon d-md-span d-span d-lg-none"><i className={iconClass}></i> </span>{intl.formatMessage({ id: "dataentry.time_spent.labels.you_spend" })} <strong>{diff + " " + intl.formatMessage({ id: "dataentry.time_spent.labels.hrs" })} </strong> {diffLabel}</span>
        } 

        return result
    }

    const getGenderAndAgeText = () => {
        let text
        if(gender === "M") {
            text = "Average " + intl.formatMessage({ id: "dataentry.time_spent.labels.men" }) + " ("+age+" yrs):"
        } else{
            text = "Average " + intl.formatMessage({ id: "dataentry.time_spent.labels.women" }) + " ("+age+" yrs):"
        }
        return text
    }

    const displayDif = () => {
        let icon = <EqualIcon />
        if (data > Number(hrs)){
            icon = <LessIcon />
        } else if (data < Number(hrs)){
            icon = <MoreIcon />
        }
        if(concept === "results"){
            return (
                <>
                    <div className="col-lg-4 text-center">{icon}</div>
                    <div className="row col-lg-8">
                        {getResultsText()}
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <span className="d-block d-sm-none col-lg-4 text-center">{getGenderAndAgeText()} {data + " " + intl.formatMessage({ id: "dataentry.time_spent.labels.h_week" })}</span>
                    <span className="d-none d-sm-block col-lg-4 text-center">{data + " " + intl.formatMessage({ id: "dataentry.time_spent.labels.h_week" })}</span>
                    <div className="row col-lg-8 col-12">
                        <div className="col-4 icon d-none d-lg-block">{icon}</div>
                        <div className="col-lg-8 text-center"> {getResultsText()}</div>
                    </div>
                </>
            )
        }   
    }

    return (
        <div className={className}>
            {displayDif()}
        </div>
    )
}

export default injectIntl(AgainstAverage)